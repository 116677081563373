import React, { Component } from "react"
import { Link, graphql } from "gatsby"
import { animateScroll as scroll} from "react-scroll"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import WrappingList from "../components/buy-equipment/wrapping-list"
import Breadcrumb from "../components/layout/breadcrumb"

import styles from "./browse-manufacturers.module.css"

const indexComponent = ({ items }) => (
  <div className={styles.padding}>
    <div className={styles.container}>
      <ul className="row letter-list">
        {items.map(s => <li key={s.toUpperCase()}><a href={`#${s.toUpperCase()}`}>{s.toUpperCase()}</a></li>)}
      </ul>
    </div>
    <h4 className="headings mt2" />
  </div>
)

const groupComponent = ({ group, items, slugPrefix }) => {
  const g = items[group];
  const n = Math.ceil(g.length / 5);
  const groups = [];
  for (let i = 0; i < g.length; i += n) {
    groups.push(g.slice(i, i + n));
  }

  return (
    <>
      <div className="row column mb6">
        <div id={group.toUpperCase()} className="letter">
          {group.toUpperCase()}
        </div>
      </div>
      <div className="row xsmall-up-2 small-up-3 medium-up-4 large-up-5">
        {groups.map((g, i) => (
          <div className="column" key={i}>
            <ul className="eq-list">
              {g.map(listedItem => (
                <li key={listedItem.name}>
                  <Link
                    className="manufacturer-list"
                    to={`../${slugPrefix}${listedItem.slug}`}
                  >
                    {listedItem.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div className="row column">
        <a  className="right" onClick={()=> scroll.scrollToTop()}>&gt;&gt; Back to Top</a>
        <hr className="headings" />
      </div>
    </>
  );
}

class Manufacturers extends Component {
  render() {
    const { data } = this.props

    var alphabet = {
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
      6: [],
      7: [],
      8: [],
      9: [],
      0: [],
      a: [],
      b: [],
      c: [],
      d: [],
      e: [],
      f: [],
      g: [],
      h: [],
      i: [],
      j: [],
      k: [],
      l: [],
      m: [],
      n: [],
      o: [],
      p: [],
      q: [],
      r: [],
      s: [],
      t: [],
      u: [],
      v: [],
      w: [],
      x: [],
      y: [],
      z: [],
    }

    return (
      <Layout>
        <SEO
          title={`Lab Equipment Manufacturers & Scientific Equipment Companies`}
          description={`Visit Surplus Solutions to browse our wide selection of manufacturers for our equipment. Our company believes in fully understanding our client’s needs and expectations from the very beginning. Contact us today for your equipment solutions.`}
          />

        <Breadcrumb paths={[ {name : "Manufacturers", path: "browse-manufacturers"}]}  />

       

        <WrappingList
          containerClassName={styles.container}
          data={data.allManufacturer.nodes}
          GroupComponent={groupComponent}
          IndexComponent={indexComponent}
          itemClassName="row"
          slugPrefix={"manufacturer"}
          />

      
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    allManufacturer(
      sort: {
        fields: [name]
        order: ASC
      }
    ) {
      totalCount
      
        nodes {
          name
          description
          slug
        }
      
    }
  }
`

export default Manufacturers
